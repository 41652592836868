import React from "react";

import "../styles/FlashingDots.css"

const FlashingDots: React.FC = () => {
    return (
        <div className="dot-flashing"></div>
    );
}

export default FlashingDots;