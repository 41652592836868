import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CoolButton from './CoolButton';
import { CircularProgressbarStyles } from 'react-circular-progressbar/dist/types';


interface UploadProgressProps {
    progress: number;
    error: string | null;
    reset: () => void;
    isLoading: boolean;
    background: string;
}

const UploadProgress: React.FC<UploadProgressProps> = ({ progress, error, reset, isLoading, background }) => {

    const overlayStyle: React.CSSProperties = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0,0,0,0.7)",
        zIndex: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Sarabun, sans-serif",
    }

    if (!isLoading && !error) return null;

    const progressStyle: CircularProgressbarStyles = {
        background: {
            fill: "rgba(0,0,0,0.7)",
            fillOpacity: 0.3
        },
        path: {
            stroke: "white",
            strokeWidth: 5,
            strokeLinecap: "revert",
        },
        trail: {
            stroke: "transparent",
        },
        text: {
            fill: "white",
            color: "white",
        }
    }

    return (
        <div style={overlayStyle}>
            <div>
            {error && <div style={{ color: "white", fontSize: "1.5em", textAlign: "center" }}>{error}</div>}
            {isLoading && <CircularProgressbar background styles={progressStyle} value={progress} text={`${progress}%`} />}
            {!isLoading && !!error && <CoolButton color='#ffffff' onClick={reset}>Tilbake</CoolButton>}
            </div>
        </div>
    );

}

export default UploadProgress;