import React, { MouseEvent } from 'react';

interface CoolButtonProps {
    color: string;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    children?: React.ReactNode;
    image?: string;
    style?: React.CSSProperties;
    textStyle?: React.CSSProperties;
    disabled?: boolean;
}

const CoolButton: React.FC<CoolButtonProps> = ({
    color,
    onClick,
    children,
    image,
    style,
    textStyle,
    disabled,
}) => {
    function getTextColor(bgColor: string): string {
        const element = document.createElement('div');
        element.style.color = bgColor;
        document.body.appendChild(element);
        const computedColor = getComputedStyle(element).color;
        document.body.removeChild(element);

        const hexColor = computedColor.match(/\d+/g);
        if (!hexColor) {
            throw new Error(`Invalid color: ${bgColor}`);
        }
        const [red, green, blue] = hexColor.map((color) => parseInt(color, 10));
        const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
        const BRIGHTNESS_THRESHOLD = 125;
        return brightness > BRIGHTNESS_THRESHOLD ? '#000' : '#fff'; // Set black or white based on brightness

    }
    const textColor = getTextColor(color);
    const buttonStyle: React.CSSProperties = {
        backgroundColor: color,
        border: 'none',
        borderRadius: '3px',
        padding: '10px 15px',
        margin: '10px',
        cursor: 'pointer',
        boxShadow: '4px 4px 0px rgba(0, 0, 0, 0.6)',
        color: textColor,
        fontSize: '1.5em',
        ...style,
    };

    const imageStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '4px',
    };

    return (
        <button className='cool-button' style={buttonStyle} onClick={onClick} disabled={disabled}>
            {image ? <img src={image} alt="" style={imageStyle} /> : <span style={textStyle}>{children}</span>}
        </button>
    );
};

export default CoolButton;
