import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import {Camera} from "react-camera-pro";

interface MainCameraProps {
  disabled?: boolean;
  setCameraReady: (ready: boolean) => void;
}

type MainCameraRef = {
  takePhoto: () => string | null;
  switchCamera: () => void;
}

const MainCamera = forwardRef<MainCameraRef, MainCameraProps>((
  { disabled, setCameraReady }, ref
) => {
  const camera = useRef(null);
  const [aspectRatio, setAspectRatio] = useState(3/4);
  const errorMessages = {
    noCameraAccessible: 'No camera device accessible. Please connect your camera and try again.',
    permissionDenied: 'Permission denied. Please refresh and give camera permission.'
  };
  useImperativeHandle(ref, () => ({
    takePhoto () {
      if (camera.current) {
        const currentCamera = camera.current as any;
        return currentCamera.takePhoto();
      } else {
        return null;
      }
    },
    switchCamera () {
      if (camera.current) {
        const currentCamera = camera.current as any;
        return currentCamera.switchCamera();
      } else {
        return null;
      }
    }
  }));

  useEffect(() => {
    function handleResize() {
      const aspectRatio = window.innerHeight > window.innerWidth ? 3/4 : 4/3;
      setAspectRatio(aspectRatio)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })


  return (
    <div style={{
      width: "100%",
      maxWidth: "min(90vh, 90vw)",
      maxHeight: "90vh",
      margin: "auto",
      marginTop: "1em",
    }}>
      <Camera 
        aspectRatio={aspectRatio}
        errorMessages={errorMessages}
        ref={camera}
        facingMode='environment'
        videoReadyCallback={() => {setCameraReady(true)}}
      />
    </div>
  );
});

export default MainCamera;