import React from 'react';
import '../styles/ImageGallery.css';

export interface ImageGalleryProps {
    photos: string[];
    hideGallery: () => void;
    color: string;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ color, photos, hideGallery }) => {
    return (
        <div className="image-gallery">
            {photos.map((url, index) => (
                <div key={index} className="image-gallery-item">
                    <img src={url} alt={`Downloaded ${index}`} width="100%" height="100%" />
                </div>
            ))}
        </div>
    );
};

export default ImageGallery;
