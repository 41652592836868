import React, { useEffect } from 'react';
import './styles/App.css';
import CameraView from './views/CameraView';
import OneTimeImageGallery from './views/OneTimeImageGallery';
import FullScreenLoading from './views/FullScreenLoading';
import { getRandomColor } from './util/randomColor';
import { track } from './util/MixPanel';
import useLocalStorage from './util/useLocalStorage';

interface Config {
  title: string;
  slug: string;
  duration: string;
  numberOfPictures: number;
  colors: string[];
  expiryDate: number;
}

function App() {
  const [isGalleryVisible, setGalleryVisible] = React.useState(false);
  const [slug, setSlug] = React.useState<string | null>(null);
  const [config, setConfig] = React.useState<Config | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [configToken, setConfigToken] = useLocalStorage("configToken", null);

  const colors = config?.colors || [getRandomColor(), getRandomColor()];
  const color = `linear-gradient(45deg, ${colors.join(",")}`;
  document.body.style.background = color;

  useEffect(() => {
    if (window.location.hostname.split(".").length < 3) {
      //There is no subdomain, check if slug is provided through query parameter
      const urlParams = new URLSearchParams(window.location.search);
      const slug = urlParams.get('slug');
      if (slug) {
        setSlug(slug);
      } else {
        //Redirect to main page
        window.location.href = "https://onetime.no";
      }
    } else {
      const slug = window.location.hostname.split(".")[0];
      setSlug(slug);
    }
  }, [])

  // Get slug from URL, where slug is the subdomain
  useEffect( () => {

    if (!slug) {
      return;
    }
    
    const CONFIG_FUNCTION_URL = process.env.REACT_APP_CONFIG_FUNCTION_URL || ""
    track("Init config", { slug: slug });
    const headers = new Headers();
    if (configToken) {
      headers.append("x-config-token", configToken);
    }
    // Get config from Azure Function
    fetch(CONFIG_FUNCTION_URL + "&slug=" + slug, {headers}).then((response) => {
      if (!response.ok) {
        throw new Error(response.status + " (" + response.statusText + ")");
      }
      response.json().then((data) => {
        setConfig(data.config);
        setConfigToken(data.token);
        setLoading(false);
        track("Config loaded", { slug: slug });
      })
    }).catch((error) => {
      console.error("Could not get config from Azure Function:", error);
      track("Config error", { slug: slug, error: error });
      //Redirect to main page
      window.location.href = "https://onetime.no";
    });

  }, [slug, configToken, setConfigToken])

  if (loading || !config || !slug) {
    return (<FullScreenLoading />)
  }

  const title = config.title

  return (
    <>
      <div className="App">
        <h1 className='title'>{title}</h1>
        {!isGalleryVisible && <CameraView color={color} showGallery={() => setGalleryVisible(true)} slug={slug} photoLimit={config.numberOfPictures} configToken={configToken} />}
      </div>
      {isGalleryVisible && <OneTimeImageGallery color={color} hideGallery={() => setGalleryVisible(false)} slug={slug} configToken={configToken} />}
    </>
  );
}

export default App;
