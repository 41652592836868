import React from "react";

import "../styles/FullScreenLoading.css"
import FlashingDots from "../components/FlashingDots";

const FullScreenLoading: React.FC = () => {
    return (
        <div className="loading-screen">
            <h1 className="loading-text">OneTime</h1>
            <p className="helper-text">Laster inn ditt kamera...</p>
            <FlashingDots />
        </div>
    );
};

export default FullScreenLoading;