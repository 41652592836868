import { useState } from "react";
import axios from "axios";

export const useUploadForm = (url: string, configToken: string | null) => {
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const uploadForm = async (formData: FormData) => {
    reset();
    setIsLoading(true);
    try {
      await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-config-token": configToken,
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / (progressEvent.total ?? progressEvent.loaded)) * 97;
          setProgress(Math.floor(progress));
        },
        onDownloadProgress: (progressEvent) => {
          const progress = 97 + (progressEvent.loaded / (progressEvent.total ?? progressEvent.loaded)) * 3;
          setProgress(Math.floor(progress));
        },
      })
      setError(null);
      setProgress(100);
    } catch (error: any) {
      console.error(error);
      const errorMessage = "Kunne ikke laste opp bildet, prøv igjen."
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const reset = () => {
    setError(null);
    setProgress(0);
    setIsLoading(false);
  };

  return { uploadForm, isLoading, error, progress, reset};
};