

// React component

import { useRef, useState } from "react";
import CoolButton from "../components/CoolButton";
import MainCamera from "../components/MainCamera";
import camera from "../assets/camera-circle.svg";
import cameraSwitch from "../assets/camera-switch.svg";
import useLocalStorage from "../util/useLocalStorage";
import { track } from "../util/MixPanel";
import { useUploadForm } from "../util/uploadForm";
import { base64ToBlob } from "../util/base64ToBlob";
import UploadProgress from "../components/UploadProgress";

interface CameraViewProps {
    showGallery: () => void;
    color: string;
    slug: string;
    photoLimit: number;
    configToken: string | null;
}

const CameraView: React.FC<CameraViewProps> = ({ color, showGallery, slug, photoLimit, configToken }) => {
    const [photosTaken, setPhotosTaken] = useLocalStorage("photosTaken", 0);
    const mainCameraRef = useRef(null);
    const [cameraReady, setCameraReady] = useState(false);

    const isCameraDisabled = photosTaken >= photoLimit;
    const REACT_APP_UPLOAD_FUNCTION_URL = process.env.REACT_APP_UPLOAD_FUNCTION_URL || "";

    const API_URL = REACT_APP_UPLOAD_FUNCTION_URL + "&username=" + slug + "&filename=image.jpg";

    const {uploadForm, error, isLoading, progress, reset} = useUploadForm(API_URL, configToken);

    const photoButtonStyle = {
        width: "3.5em",
        margin: "10px",
        padding: "0.5em",
        height: "3.5em",
        color: "white",
        //background: "none",
        boxShadow: "none",
        background: "none"
    }
    const cameraSwitchButtonStyle = {
        width: "2em",
        height: "2em",
        margin: "10px",
        borderRadius: "25%",
        padding: "0.1em",
        background: "none",
        boxShadow: "none",
    }

    const galleryButtonStyle = (color: string) => ({
        backgroundColor: "white",
        background: color,
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        color: "unset",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        MozBackgroundClip: "text",
        MozTextFillColor: "transparent",
    })
    const uploadImage = async (base64String: string) => {
        const blob = base64ToBlob(base64String);

        const formData = new FormData();
        formData.append('image', blob, 'image.jpg');

        try {
            await uploadForm(formData);
            setPhotosTaken(photosTaken + 1);
            setCameraReady(false);
        } catch {
            console.error("Failed to upload image");
        }
    };

    const takePhoto = async () => {
        const mainCamera = mainCameraRef.current as any;
        if (mainCamera) {
            const photo = mainCamera.takePhoto();
            if (photo) {
                track("photo_taken", { slug: slug });
                await uploadImage(photo);
            }
        }
    }

    const switchCamera = () => {
        const mainCamera = mainCameraRef.current as any;
        if (mainCamera) {
            mainCamera.switchCamera();
            track("camera_switch", { slug: slug });
        }
    }

    const isGalleryButtonDisabled = Boolean(isLoading || error);
    const hasJustTakenPhoto = !error && !isLoading && progress === 100;
    const areCameraButtonsDisabled = Boolean(!cameraReady || isCameraDisabled || isLoading || error || hasJustTakenPhoto);

    return (
        <header className="App-header">
            <p style={{margin: "0"}}>Du har tatt {photosTaken} / {photoLimit} bilder</p>
            {isCameraDisabled && <p>Du har brukt opp bildene dine:) Men - ta gjerne en titt i galleriet!</p>}
            {hasJustTakenPhoto && <>
                <h2>Bildet ble lastet opp!</h2>
                <CoolButton color="rgba(0,0,0,0.8)" textStyle={galleryButtonStyle(color)} onClick={reset} >Ta nytt bilde</CoolButton>
            </>}
            {!isCameraDisabled && !hasJustTakenPhoto && !cameraReady && <p>Laster inn kamera</p>}
            {!isCameraDisabled && !hasJustTakenPhoto && <MainCamera ref={mainCameraRef} disabled={isGalleryButtonDisabled} setCameraReady={setCameraReady} />}
            <div className="camera-buttons">
                {!areCameraButtonsDisabled && <CoolButton style={cameraSwitchButtonStyle} color="white" onClick={switchCamera} image={cameraSwitch} />}
                {!areCameraButtonsDisabled && <CoolButton style={photoButtonStyle} color="white" onClick={takePhoto} image={camera} />}
                <CoolButton disabled={isGalleryButtonDisabled} color="white" textStyle={galleryButtonStyle(color)} onClick={showGallery} >Se galleri</CoolButton>
            </div>
            <UploadProgress background={color} error={error} isLoading={isLoading} progress={progress} reset={reset} />
        </header>
    )
}

export default CameraView;