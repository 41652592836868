import React, {useState} from 'react';
import ImageGallery, { ImageGalleryProps } from '../components/ImageGallery';
import CoolButton from '../components/CoolButton';
import "../styles/OneTimeImageGallery.css";
import { track } from '../util/MixPanel';
import ShareOverlay from '../components/ShareOverlay';

// Remove photos from props, and add a new prop called slug
type OneTimeImageGalleryProps = Omit<ImageGalleryProps, "photos"> & {
    slug: string;
    configToken: string | null;
}

const OneTimeImageGallery: React.FC<OneTimeImageGalleryProps> = (props) => {
    const [photos, setPhotos] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [shareOverlayVisible, setShareOverlayVisible] = useState<boolean>(false);

    React.useEffect(() => {
        const REACT_APP_GALLERY_FUNCTION_URL = process.env.REACT_APP_GALLERY_FUNCTION_URL || "";
        
        const headers = new Headers();
        if (props.configToken) {
            headers.append("x-config-token", props.configToken);
        }

        fetch(REACT_APP_GALLERY_FUNCTION_URL + "&username="+props.slug, {headers}).then((response) => {
            if (!response.ok) {
                throw new Error(response.status + " (" + response.statusText + ")");
            }
            response.json().then((data) => {
                setPhotos(data.urls || []);
                setLoading(false);
                track("Gallery loaded", { slug: props.slug });
            })
        })
        .catch((error) => {
            console.error("Could not get photos from Azure Function:", error);
            track("Gallery error", { slug: props.slug, error: error });
            setError("Kunne ikke hente bilder fra serveren. Prøv igjen senere.");
            setLoading(false);
        });
    }, [props.slug, props.configToken])

    const aboutButtonStyle = {
        backgroundColor: "white",
        background: props.color,
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        color: "unset",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        MozBackgroundClip: "text",
        MozTextFillColor: "transparent",
    }

    const showShareOverlay = () => {
        setShareOverlayVisible(true);
    }

    const hideShareOverlay = () => {
        setShareOverlayVisible(false);
    }

    return (
        <div style={{ margin: 0, height: "100%" }}>
            <div className='image-gallery-header'>
                <div className='image-gallery-header-buttons'>
                    <CoolButton color='white' style={{ maxWidth: "5em", height: "2em" }} onClick={props.hideGallery}>Tilbake</CoolButton>
                    <CoolButton color='white' textStyle={aboutButtonStyle} onClick={showShareOverlay}>Del</CoolButton>
                </div>
                <h1>Gallery</h1>
                {!loading && !error && photos.length === 0 && <p className="image-gallery-empty">Ingen bilder tatt enda, du kan bli den første! 🎉</p>}
            </div>
            <div className="content">
                {loading && <div className="image-gallery-empty">Laster inn bilder...</div>}
                {error && <div className="image-gallery-empty">{error}</div>}
                {!loading && !error && photos.length > 0 && <ImageGallery {...props} photos={photos} />}
            </div>
            {shareOverlayVisible && <ShareOverlay url={`https://${props.slug}.onetime.no/`} closeOverlay={hideShareOverlay} />}
        </div>
    );
}

export default OneTimeImageGallery;