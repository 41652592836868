import React, { useState } from 'react';
import QRCode from 'react-qr-code';

import '../styles/ShareOverlay.css';

interface ShareOverlayProps {
    url: string;
    closeOverlay: ()=>void;
}

const ShareOverlay: React.FC<ShareOverlayProps> = ({ url, closeOverlay }) => {

    const [buttonText, setButtonText] = useState("Kopiér link");
    
    const copyToClipboard = () => {
        navigator.clipboard.writeText(url);
        setButtonText("Kopiert!");
    };

    // when clicking outside the overlay content, close the overlay
    const closeOverlayOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (event.target === event.currentTarget) {
            setButtonText("Kopiér link");
            closeOverlay();
        }
    }
    
    return (
        <div className="overlay" onClick={closeOverlayOnClick}>
            <div className="overlay-content">
                <h1>Del siden</h1>
                <p>Scan QR-koden eller kopier linken under for å dele kameraet med dine venner!</p>
                <div className="qr-code-container">
                <QRCode value={url} />
                </div>
                <div className="url-container">
                <input type="text" value={url} readOnly />
                <button onClick={copyToClipboard}>{buttonText}</button>
                </div>
                <p className='credits'>Laget med ♥︎ av Dag Sommer</p>
            </div>
        </div>
    );
};

export default ShareOverlay;